import React from "react"
import Layout from "../../components/Layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

import BoardData from "../../../content/board.json"

export default function Board({ pageContext: { language }, data: { boardPics } }) {
	
  const cx = classNames.bind(styles);

	return(
		<Layout
      lang={language}
			availableLanguages={["th", "en"]}
      title={"PIER Board"}
      pathname={"/board/"}
			customTitle={true}
    >
      <h1 className="h2__style">
      {
        language === "th"
        ? "คณะกรรมการสถาบันวิจัยเศรษฐกิจป๋วย อึ๊งภากรณ์"
        : "PIER Board of Directors"
      }
      </h1>
      <div className={cx("container")}>
        {BoardData.map((person, i) => 
          <div className={cx("person")} key={`person${i}`}>
            <GatsbyImage image={boardPics.nodes.filter(b => b.base === person.pic)[0].childImageSharp.gatsbyImageData} alt={person[language].name} />
            <div className={cx("name")}>
              {person[language].name}
            </div>
            <div className={cx("position")}>
              {person[language].position}
            </div>
          </div>
        )}
      </div>
		</Layout>
	)

}

export const pageQuery = graphql`
	query BoardQuery {
		boardPics: allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "board"}}) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 300)
        }
      }
    }
	}
`